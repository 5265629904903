/*-----------------------
    Contact area
-----------------------*/
.contact-area{
    padding: 110px 0 120px 0;
    .left-content-area{
        .section-inner-title{
            margin-bottom: 56px;
            .subtitle{
                font-size: 18px;
                line-height: 28px;
                color: $base-color;
                text-transform: uppercase;
                font-weight: 700;
                font-family: "Poppins", sans-serif;
            }
            .title{
                font-size: 50px;
                line-height: 60px;
                font-weight: 700;
            }
            p{
                font-size: 16px;
                line-height: 26px;
                color: #505b6d;
                font-family: $body-font;
            }
        }
        .contact-form-wrapper{

        }
    }
    .right-content-aera{
        margin-top: 20px;
        text-align: right;
        .img-wrapper{}
    }
}
.contact-form{
    .form-group{
        margin-bottom: 20px;
        .form-control{
            height: 50px;
            border: 1px solid #e6e6e6;
        }
        &.textarea{
            .form-control{
                min-height: 150px;
                resize: none;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
    .submit-btn{
        background-image: linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
        background-image: -moz-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
        background-image: -webkit-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
        background-image: -ms-linear-gradient( -4deg, rgb(0,132,255) 0%, rgb(68,86,255) 44%, rgb(136,40,255) 100%);
        border-radius: 30px;
        width: 180px;;
        padding:0px;
          &:hover{
            box-shadow: 0px 3px 20px 0px rgba(13, 21, 75, 0.3);
          }
    }

}
