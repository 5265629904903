	/*
  	Flaticon icon font: Flaticon
  	Creation date: 06/11/2018 20:40
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: inherit;
  font-style: normal;
}

.flaticon-education:before { content: "\f100"; }
.flaticon-support:before { content: "\f101"; }
.flaticon-global:before { content: "\f102"; }
.flaticon-arrow:before { content: "\f103"; }
.flaticon-download:before { content: "\f104"; }
.flaticon-good:before { content: "\f105"; }
.flaticon-next:before { content: "\f106"; }
.flaticon-back:before { content: "\f107"; }
.flaticon-shield:before { content: "\f108"; }
.flaticon-vision:before { content: "\f109"; }
.flaticon-trophy:before { content: "\f10a"; }